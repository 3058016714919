
const LoadingList = (): JSX.Element => {
    return (
        <div role="status" className="space-y-0 w-full rounded shadow animate-pulse">
            <div className="flex justify-between items-center h-16 px-2.5">
                <div className="w-32 h-2.5 bg-gray-300 rounded-full dark:bg-gray-600"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
            <div className="w-full flex justify-between items-center bg-gray-50 h-16 py-1.5 px-2.5 dark:bg-zinc-800">
                <div className="w-32 h-2.5 bg-gray-300 rounded-full dark:bg-gray-600"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
            </div>
          <div className="flex justify-between items-center h-16 py-1.5 px-2.5">
                <div className="w-32 h-2.5 bg-gray-300 rounded-full dark:bg-gray-600"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <div className="flex justify-between items-center h-16 py-1.5 px-2.5 bg-gray-50 dark:bg-zinc-800">
                <div className="w-32 h-2.5 bg-gray-300 rounded-full dark:bg-gray-600"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <div className="flex justify-between items-center h-16 py-1.5 px-2.5">
                <div className="w-32 h-2.5 bg-gray-300 rounded-full dark:bg-gray-600"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <div className="flex justify-between items-center h-16 py-1.5 px-2.5 bg-gray-50 dark:bg-zinc-800">
                <div className="w-32 h-2.5 bg-gray-300 rounded-full dark:bg-gray-600"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
    );
};

export default LoadingList;