import React from 'react';
import {
  BrowserRouter as Router,
  Switch, Route, Redirect
} from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import './App.scss';
import { isAuth } from './share/auth';
import { SignIn } from './signin';
import Portal from './portal/portal';
import { IToken } from './share/interface';

const httpLink = createHttpLink({
  uri: 'https://fe.romacc.com.ec/poscye/api/graphql',
});

const authLink = setContext((_, { headers }) => {
  const llStorage = window.localStorage;
  const tk: IToken = JSON.parse(llStorage.getItem('atpe') as string);
  const head = {
    headers: {
      ...headers
    }
  };
  if (tk && tk.token){ head.headers.authorization = `${tk.token}`};
  return head;
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <PrivateRoute path="/portal">
            <Portal />
          </PrivateRoute>
          <Route path="/">
            <SignIn />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

function PrivateRoute({ children, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth.getIsAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default App;

// 6LeDdGohAAAAAC8CF5U9FNKKpRgQF_GEHduQ2uJe
// 6LeDdGohAAAAAPkNlFiKbk2EDshZqriVTgxMFVze

// 6LcJEnAhAAAAAITs2xW-i0hZHH_GTTWjKAlRIM2k
// 6LcJEnAhAAAAAFZWqboO0rImF8wOwT3koI9k4Uny